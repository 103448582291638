import { CatalogItem } from "@/models/Catalog";

export function bool2IconFilter(value: boolean) {
  if (value === true) {
    return "fa fa-check-circle text-success";
  }
  return "fa fa-times-circle text-danger";
}

export function bool2IconFilterStarred(value: boolean) {
  if (value === true) {
    return "recommended-cards fa-regular fa fa-star";
  }
  return "unrecommended-cards fa-regular far fa-star";
}

export function daysFilter(value: any) {
  const weekdays = ["L", "M", "X", "J", "V", "S", "D"];
  return value.map((numero: any) => weekdays[numero]).join(", ");
}

export function shipmentMaxSizeFilter(value: number) {
  if (value === 0) {
    return "Sin límite";
  }
  return value;
}

export function countryCode2FullNameFilter(
  value: string,
  catalog: CatalogItem<string>[]
) {
  const item = catalog.find(x => x.id === value);
  if (item === undefined) {
    return "";
  }
  return item.description;
}
